import { api } from ".";

export interface ItemsAllSitesResponseProps {
  id: string;
  name: string;
  title: string;
  domain: string;
}

export interface GetAllSitesRequestResponseProps {
  items: ItemsAllSitesResponseProps[];
  total: number;
}

export const getAllSitesRequest =
  async (): Promise<GetAllSitesRequestResponseProps> => {
    const { data } = await api.get<GetAllSitesRequestResponseProps>("site/all");
    return data;
  };
