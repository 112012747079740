import { api } from ".";

interface PostListNewsToRepublishRequestProps {
  start_date: string;
  end_date: string;
  site_id: string;
}

export interface PostListNewsToRepublishResponseProps {
  start_date: string;
  end_date: string;
  site_id: string;
  total_news: number;
}

export const postListNewsToRepublishRequest = async (
  props: PostListNewsToRepublishRequestProps
): Promise<PostListNewsToRepublishResponseProps> => {
  const { data } = await api.post<PostListNewsToRepublishResponseProps>(
    "/batch/list-news-to-republish",
    props
  );
  return data;
};
