import { api } from ".";

interface PostCreateBatchRequestProps {
  start_date: string;
  end_date: string;
  site_id: string;
  total_news: number;
}
interface PostCreateBatchResponseProps {
  uid: string;
  batch_items: {
    uid: string;
    site_id: string;
    site_name: string;
    initial_date: string;
    final_date: string;
    news_ids: {
      uid: string;
      republished: boolean;
    }[];
    additional_templates: string[];
    status: string;
    created_at: string;
    updated_at: string;
  }[];
  status: string;
  attempt: number;
  created_at: string;
  updated_at: string;
}

export const postCreateBatchRequest = async (
  props: PostCreateBatchRequestProps
): Promise<PostCreateBatchResponseProps> => {
  const { data } = await api.post<PostCreateBatchResponseProps>(
    "/batch/create-batch",
    props
  );
  return data;
};
