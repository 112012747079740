import axios from 'axios'

export const BASE_API_URL = 'https://service.ig.com.br/republisher-backend/v1'

//export const BASE_API_URL = 'http://localhost:3009/republisher-backend/v1'

const authenticated = localStorage.getItem('access_token')

export const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: `Bearer ${authenticated}`,
    'x-api-key': 'baa7f8ea-7515-4913-b46d-ae2911d5374e',
  },
})
