import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login";
import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { Home } from "../pages/home";

export const Routers = () => {
  const isAuthenticated = !!localStorage.getItem("access_token");

  function Protected({
    isSignedIn,
    children,
  }: {
    isSignedIn: boolean;
    children: ReactNode | any;
  }) {
    if (!isSignedIn) {
      return <Navigate to="/login" replace />;
    }
    return children;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Protected isSignedIn={isAuthenticated}>
              <Home />
            </Protected>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};
