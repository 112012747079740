import { api } from ".";

export interface GetListBatchesResponseProps {
  uid: string;
  site_name: string;
  start_date: string;
  end_date: string;
  total_news: number;
  status: string;
  created_at: string;
  updated_at: string;
}

export const getListBatchesRequest = async (): Promise<
  GetListBatchesResponseProps[]
> => {
  const { data } = await api.get<GetListBatchesResponseProps[]>(
    "batch/list-batches"
  );
  return data;
};
