import { api } from ".";

export interface ProcessBatchStatus {
  republished: number;
  in_progress: number;
  failed: number;
}

export interface GetBatchInformationResponseProps {
  uid: string;
  site_id: string;
  site_name: string;
  initial_date: string;
  final_date: string;
  status: string;
  process_batch_status: ProcessBatchStatus;
  total: number;
  created_at: string;
  updated_at: string;
}

export const getBatchInformationRequest = async (
  id: string
): Promise<GetBatchInformationResponseProps> => {
  const { data } = await api.post<GetBatchInformationResponseProps>(
    "batch/get-batch-information",
    { uid: id }
  );
  return data;
};
