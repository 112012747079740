import { api } from ".";

interface PostLoginRequestProps {
  username: string;
  password: string;
}

interface PostLoginResponseProps {
  access_token: string;
}

export const postLoginRequest = async (
  props: PostLoginRequestProps
): Promise<PostLoginResponseProps> => {
  const { data } = await api.post<PostLoginResponseProps>(
    "authentication/sign-in",
    props
  );
  localStorage.setItem("access_token", data.access_token);
  return data;
};
