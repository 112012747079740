import { api } from ".";


interface PostReprocessBatchResponseProps {
    status: number,
    message: string
 }

export const postReprocessBatchRequest = async (
    uid: string
    ): Promise<PostReprocessBatchResponseProps> => {
  const { data } = await api.post<PostReprocessBatchResponseProps>(
    `batch/reprocess-batch/${uid}`,
  );
  return data;
};
