import { api } from ".";

export interface BatchItemsWithErrors {
  uid: string;
  site_id: string;
  site_name: string;
  initial_date: string;
  final_date: string;
  news_ids_with_errors: string[];
  additional_templates: string[];
  status: string;
  created_at: string;
  updated_at: string;
}

export interface GetBatchErrorsInformationResponseProps {
  uid: string;
	site_id: string;
	site_name: string;
	start_date: string;
	end_date: string;
	status: string;
	batch_items: BatchItemsWithErrors[];
	created_at: string;
	updated_at: string;
}

export const getBatchErrorsInformationRequest = async (
  id: string
): Promise<GetBatchErrorsInformationResponseProps> => {
  const path = `batch/batch-errors-information/${id}`;
  
  const { data } = await api.get<GetBatchErrorsInformationResponseProps>(
    path
  );
  return data;
};
