import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSearch,
  faTrash,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { TopBar } from '../../components/header';
import {
  ItemsAllSitesResponseProps,
  getAllSitesRequest,
} from '../../service/getAllSitesRequest';
import {
  GetBatchInformationResponseProps,
  getBatchInformationRequest,
} from '../../service/getBatchInformationRequest';
import {
  GetListBatchesResponseProps,
  getListBatchesRequest,
} from '../../service/getListBatchesRequest';
import { postCreateBatchRequest } from '../../service/postCreateBatchRequest';
import {
  PostListNewsToRepublishResponseProps,
  postListNewsToRepublishRequest,
} from '../../service/postListNewsToRepublishRequest';
import { postReprocessBatchRequest } from '../../service/postReprocessBatch';
import { postBatchCancellationRequest } from '../../service/batchCancellationRequest';
import { deleteBatchRequest } from '../../service/deleteBatchRequest';
import { io } from 'socket.io-client';
import {
  BatchItemsWithErrors,
  GetBatchErrorsInformationResponseProps,
  getBatchErrorsInformationRequest,
} from '../../service/getBatchErrorsInformation';
import ptBR from 'antd/lib/locale/pt_BR';

const { Option } = Select;

export const Home = () => {
  const [batch, setBatch] = useState<GetListBatchesResponseProps[] | undefined>(
    undefined
  );

  const [listSites, setListSites] = useState<
    ItemsAllSitesResponseProps[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectSite, setSelectSite] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(7, 'days').format('YYYY-MM-DD') as string
  );
  const [endDate, setEndDate] = useState<string>(
    moment().format('YYYY-MM-DD') as string
  );
  const [dataListNewsToRepublish, setDataListNewsToRepublish] = useState<
    PostListNewsToRepublishResponseProps | undefined
  >(undefined);
  const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
  const [batchInformation, setBatchInformation] = useState<
    GetBatchInformationResponseProps | undefined
  >(undefined);
  const [showModalErrorsDetails, setShowModalErrorsDetails] =
    useState<boolean>(false);
  const [batchErrorsInformation, setBatchErrorsInformation] = useState<
    GetBatchErrorsInformationResponseProps | undefined
  >(undefined);

  const getAllSites = async () => {
    try {
      const data = await getAllSitesRequest();
      const items = data.items;

      const sortedItems = items.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });

      setListSites(sortedItems);
    } catch (error: Error | any) {
      if (error.response.status === 401) {
        window.location.href = '/login';
        return;
      }

      toast.error('Erro ao buscar os sites');
    }
  };

  const fetchBatch = async () => {
    try {
      const data = await getListBatchesRequest();
      setBatch(data);
    } catch (error: Error | any) {
      if (error.response.status === 429) {
        return;
      } else if (error.response.status === 401) {
        window.location.href = '/login';
        return;
      }
      toast.error('Erro ao buscar os lotes');
    }
  };

  useEffect(() => {
    getAllSites();
    fetchBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date: string) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  };

  const showDetails = async (uid: string) => {
    try {
      const data = await getBatchInformationRequest(uid);
      setBatchInformation(data);
      setShowModalDetails(true);
    } catch (error) {
      toast.error('Erro ao buscar os detalhes do lote');
    }
  };

  const showErrorsDetails = async (uid: string) => {
    try {
      const data = await getBatchErrorsInformationRequest(uid);
      setBatchErrorsInformation(data);
      setShowModalErrorsDetails(true);
    } catch (error) {
      toast.error('Erro ao buscar os detalhes dos erros do lote');
    }
  };

  const countErrors = (batchItems: BatchItemsWithErrors[] | undefined) => {
    let count = 0;

    batchItems?.forEach((item) => {
      item.news_ids_with_errors.forEach((news) => count++);
    });

    return count;
  };

  const showModal = async () => {
    try {
      setIsLoading(true);
      const data = await postListNewsToRepublishRequest({
        site_id: selectSite,
        end_date: String(endDate),
        start_date: String(startDate),
      });
      setDataListNewsToRepublish(data);
      setIsModalOpen(true);
      setIsLoading(false);
    } catch (error) {
      toast.error('Erro ao buscar os detalhes do lote');
      setIsLoading(false);
    }
  };

  const handleOk = async () => {
    if (!dataListNewsToRepublish) {
      return;
    }
    try {
      await postCreateBatchRequest(dataListNewsToRepublish);
      setIsModalOpen(false);
    } catch (error) {
      toast.error('Erro ao criar o lote');
    }
  };

  const closeModalDetails = () => {
    setShowModalDetails(false);
  };

  const closeModalErrorsDetails = () => {
    setShowModalErrorsDetails(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const reprocessBatch = async (uid: string) => {
    try {
      const toReprocess = postReprocessBatchRequest(uid);

      if (toReprocess instanceof String) {
        toast.error(
          'Já existe um lote em processamento. Aguarde a finalização para reprocessar novamente'
        );
      }
      toast.success('Lote enviado para reprocessamento com sucesso');
    } catch (error) {
      toast.error('Erro ao reprocessar o lote');
    }
  };

  const cancelBatch = async (uid: string) => {
    try {
      postBatchCancellationRequest(uid);
      toast.success('Lote cancelado com sucesso');
    } catch (error) {
      toast.error('Erro ao cancelar o lote');
    }
  };

  const deleteBatch = async (uid: string) => {
    try {
      deleteBatchRequest(uid);
      toast.success('Lote excluído com sucesso');
    } catch (error) {
      toast.error('Erro ao excluir o lote');
    }
  };

  const renderStatus = (status: string) => {
    let text = '';
    let color = '';

    switch (status) {
      case 'FINISHED':
        text = 'Concluído';
        color = 'green';
        break;
      case 'IN_PROGRESS':
        text = 'Processando';
        color = 'gold';
        break;
      case 'FAILED':
        text = 'Falhou';
        color = 'red';
        break;
      case 'CREATED':
        text = 'Criado';
        color = 'blue';
        break;
      case 'FINISHED_WITH_ERRORS':
        text = 'Concluído com erros';
        color = 'purple';
        break;
      case 'CANCELED':
        text = 'Cancelado';
        color = 'red';
        break;
      case 'WAITING':
        text = 'Aguardando';
        color = 'orange';
        break;
      default:
        text = status;
        break;
    }

    return <Tag color={color}>{text}</Tag>;
  };

  const columns: any = [
    {
      title: 'Site',
      dataIndex: 'site_name',
      render: (site_name: string) => <span>{site_name}</span>,
      align: 'center',
    },
    {
      title: 'Data de Início',
      dataIndex: 'start_date',
      render: (start_date: string) => <span>{formatDate(start_date)}</span>,
      align: 'center',
    },
    {
      title: 'Data de fim',
      dataIndex: 'end_date',
      render: (end_date: string) => <span>{formatDate(end_date)}</span>,
      align: 'center',
    },
    {
      title: 'Total de Notícias',
      dataIndex: 'total_news',
      render: (total_news: string) => <span>{total_news}</span>,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: renderStatus,
      align: 'center',
    },
    {
      title: 'Finalizado',
      dataIndex: 'updated_at',
      render: (updated_at: string) => <span>{formatDate(updated_at)}</span>,
      align: 'center',
    },
    {
      render: (item: any) => (
        <span className="tooltip-container">
          {item.status !== 'FINISHED_WITH_ERRORS' && (
            <span>
              <Button
                className="details-button"
                onClick={() => showDetails(item.uid)}
              >
                <FontAwesomeIcon icon={faSearch} className="button-icon" />
              </Button>
              <span className="tooltip-text">Detalhes</span>
            </span>
          )}

          {item.status === 'FINISHED_WITH_ERRORS' && (
            <span>
              <Button
                className="details-button"
                onClick={() => showErrorsDetails(item.uid)}
              >
                <FontAwesomeIcon icon={faSearch} className="button-icon" />
              </Button>
              <span className="tooltip-text">Detalhes</span>
            </span>
          )}
        </span>
      ),
      align: 'center',
    },
    {
      render: (item: any) => (
        <span>
          {(item.status === 'FAILED' ||
            item.status === 'CANCELED' ||
            item.status === 'FINISHED_WITH_ERRORS') && (
            <span className="tooltip-container">
              <Button
                className="reprocess-button"
                onClick={() => reprocessBatch(item.uid)}
              >
                <FontAwesomeIcon icon={faRedo} className="button-icon" />
              </Button>
              <span className="tooltip-text">Reprocessar</span>
            </span>
          )}

          {item.status === 'IN_PROGRESS' && (
            <span className="tooltip-container">
              <Button
                className="delete-button"
                onClick={() => cancelBatch(item.uid)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <span className="tooltip-text">Cancelar</span>
            </span>
          )}
        </span>
      ),
      align: 'center',
    },

    {
      render: (item: any) => (
        <span className="tooltip-container">
          <Button
            className="delete-button"
            onClick={() => deleteBatch(item.uid)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <span className="tooltip-text">Excluir</span>
        </span>
      ),
      align: 'center',
    },
  ];

  const [batchId, setBatchStatus] = useState();

  useEffect(() => {
    const socket = io(
      'https://service.ig.com.br/batch-processing-status',
      //"http://localhost:3009/batch-processing-status",
      {
        path: '/republisher-backend/socket.io',
      }
    );

    socket.on('connect', () => {
      socket.emit('join-room', `60dcc2f5-c7d0-4c58-8eb3-9846ac6ed098`);
    });

    socket.on('batch-processing-status', (data: any) => {
      const { batch_uid } = data;
      setBatchStatus(batch_uid);

      fetchBatch();
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listSites) {
      setSelectSite(listSites[0].id);
    }
  }, [listSites]);

  return (
    <>
      <TopBar />
      {batchId && <div></div>}
      {listSites && (
        <div className="container">
          <Divider />
          <Card>
            <h3>Criar Republicação</h3>
            <Row gutter={18}>
              <Col>
                <Select
                  style={{ width: 180 }}
                  placeholder="Selecione o site"
                  onChange={(value) => setSelectSite(value)}
                  defaultValue={listSites[0].id}
                  options={listSites.map((item) => ({
                    value: item.id,
                    label: item.title,
                  }))}
                />
              </Col>
              <Col>
                <Select value={'De:'} disabled showArrow={false}>
                  <Option>De:</Option>
                </Select>
                <ConfigProvider locale={ptBR}>
                  <DatePicker
                    defaultValue={dayjs(startDate)}
                    value={dayjs(startDate)}
                    placeholder="Data inicial"
                    onChange={(_, dateString) => setStartDate(!dateString ? startDate : dateString)}
                  />
                </ConfigProvider>
              </Col>
              <Col>
                <Select value={'Até:'} disabled showArrow={false}>
                  <Option>Até:</Option>
                </Select>
                <ConfigProvider locale={ptBR}>
                  <DatePicker
                    defaultValue={dayjs(endDate)}
                    value={dayjs(endDate)}
                    placeholder="Data final"
                    onChange={(_, dateString) => setEndDate(!dateString ? endDate : dateString)}
                  />
                </ConfigProvider>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={showModal}
                  disabled={!selectSite || !startDate || !endDate}
                >
                  Consultar
                </Button>
              </Col>
            </Row>
          </Card>
          {batch && (
            <div>
              <Divider />
              <h2>Republicações</h2>
              <Table columns={columns} dataSource={batch} />
            </div>
          )}
          <Modal
            title="Notícias para republicar"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            okText="Criar Lote"
          >
            <p>{dataListNewsToRepublish?.total_news}</p>
          </Modal>
          <Modal
            title="Detalhes"
            open={showModalDetails}
            onOk={closeModalDetails}
            onCancel={closeModalDetails}
          >
            <div>Total: {batchInformation?.total}</div>
            <div>
              Sucesso: {batchInformation?.process_batch_status.republished}
            </div>
            <div>
              Aguardando:{' '}
              {batchInformation?.process_batch_status.republished === 0
                ? batchInformation?.process_batch_status.in_progress
                : 0}
            </div>
            <div>
              Processando:{' '}
              {batchInformation &&
              batchInformation?.process_batch_status.republished > 0
                ? batchInformation?.process_batch_status.in_progress
                : 0}
            </div>
            <div>Erro: {batchInformation?.process_batch_status.failed}</div>
          </Modal>
          <Modal
            //title="Detalhes dos Erros"
            open={showModalErrorsDetails}
            onOk={closeModalErrorsDetails}
            onCancel={closeModalErrorsDetails}
            footer={null}
          >
            <div>
              <h1>Detalhes dos Erros</h1>
            </div>
            <div>
              <h2>Resumo:</h2>
              <p>
                <strong>UID:</strong> {batchErrorsInformation?.uid}
              </p>
              <p>
                <strong>ID do Site:</strong> {batchErrorsInformation?.site_id}
              </p>
              <p>
                <strong>Nome do Site:</strong>{' '}
                {batchErrorsInformation?.site_name}
              </p>
              <p>
                <strong>Status:</strong> {batchErrorsInformation?.status}
              </p>
              <p>
                <strong>Quantidade de Erros:</strong>{' '}
                {countErrors(batchErrorsInformation?.batch_items)}
              </p>
              <Divider />
            </div>
            <div>
              <h2>Itens com Erros:</h2>
              {batchErrorsInformation?.batch_items.map((item) => (
                <div key={item.uid}>
                  <p>
                    <strong>UID:</strong> {item.uid}
                  </p>
                  <p>
                    <strong>Status:</strong> {item.status}
                  </p>
                  <p>
                    <strong>IDs Com Erros:</strong>{' '}
                    {item.news_ids_with_errors.join(', ')}
                  </p>
                  <p>
                    <strong>Templates Adicionais:</strong>{' '}
                    {item.additional_templates.join(', ')}
                  </p>
                  <Divider />
                </div>
              ))}
              <Divider />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
