import { Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";

const items1 = [
  { key: "", label: "Home" },
  { key: "login", label: "Sair" },
].map((item) => ({
  key: item.key,
  label: item.label,
}));

export const TopBar = () => {
  const navigate = useNavigate();

  const handleNavigation = (key: string) => {
    if (key === "login") {
      localStorage.removeItem("access_token");
    }
  };

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <h4 onClick={() => navigate("/")}>Republicador</h4>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["2"]}
        items={items1}
        onClick={({ key }) => {
          navigate(`/${key}`);
          handleNavigation(key);
        }}
      />
    </Header>
  );
};
